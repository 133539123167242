import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useAthleteUser = (id) => {
  const path = `/athleteuser/${id}`;
  const { data: athleteUser, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  //   const updateCoachUser = async (updatedUser) => {
  //     await axios.put(path, updatedUser);
  //     mutate(path);
  //     mutate("/user");
  //   };

  const updateAthleteUser = async (updateAthleteUser) => {
    await axios.put(path, updateAthleteUser);
    mutate(path);
  };

  const deleteAthleteUser = async () => {
    await axios.delete(path);
    mutate("/athleteuser");
  };

  return {
    athleteUser,
    updateAthleteUser,
    deleteAthleteUser,
    isLoading: !error && !athleteUser,
    isError: error,
  };
};

export default useAthleteUser;
